<template>
  <div class="container">
    <prismic-image :field="data.primary.image" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.container {
  margin: 3rem 0;
  width: 100%;
  max-height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  @media print {
    display: none;
    page-break-after: always;
    page-break-before: always;
  }
}
img {
  width: 100%;
  @include respond-to("md-down") {
    min-width: 100%;
  }
}
.text {
  &::v-deep(p) {
    & a {
      text-decoration: underline;
    }
  }
}
</style>
