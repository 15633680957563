<template>
  <div class="container">
    <h2 class="xl-title">{{ asText(data.primary.title) }}</h2>
    <span class="sub" v-if="data.primary.date">{{
      asDate(data.primary.date).toLocaleDateString(undefined, {
        weekday: "long",
        month: "long",
        day: "numeric",
        timeZone: "Europe/Paris",
      })
    }}</span>
    <div
      class="tab"
      v-for="(item, index) in data.items"
      :key="index"
      :class="[{ first: index == 0 }]"
    >
      <div class="xs-title activity">
        <h3>{{ asText(item.activity_title) }}</h3>
        <span class="text" v-if="item.schedule">{{ item.schedule }}</span>
      </div>
      <div class="location">
        <prismic-rich-text :field="item.location" class="text" />
      </div>
      <div class="description">
        <prismic-rich-text :field="item.description" class="text" />
      </div>
      <!-- <div class="link"><a :href="asLink(item.link)" target="_blank" rel="noopener noreferrer" class="text">{{item.link.url.includes('pdf') ? t("journey.link.document") : t("journey.link.site") }}<img :src="`${$URL}assets/img/arrow.svg`" alt=""></a></div> -->
    </div>
  </div>
</template>

<script>
import { usePrismic } from "@prismicio/vue";
import { useI18n } from "vue-i18n";

export default {
  inject: ["$URL"],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup() {
    const { t } = useI18n();
    const { asText, asDate, asLink } = usePrismic();
    return { asText, asDate, asLink, t };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.container {
  padding: 0 8rem;
  @include respond-to("md-down") {
    padding: 0 2rem;
  }
  @media print {
    padding: 0 1rem;
    page-break-before: always;
  }
}

h2 {
  margin-top: 8rem;
  text-align: center;
  @include respond-to("md-down") {
    margin-top: 3.5rem;
  }
  @media print {
    margin-top: 2rem;
  }
}

span.sub {
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 5rem;
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.3875rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.03em;
  @include respond-to("md-down") {
    font-size: 16px;
  }
  @media print {
    margin-top: 0.2rem;
    margin-bottom: 2rem;
  }
}

.tab {
  display: flex;
  // align-items: center;
  width: 100%;
  min-height: 140px;
  padding: 30px 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  @include respond-to("md-down") {
    flex-wrap: wrap;
  }
  @media print {
    padding: 0.2cm 0;
    min-height: 2cm;
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(:last-child) {
      border-right: 2px solid rgba(0, 0, 0, 0.1);
    }
    &:nth-child(3) {
      @media print {
        border-right: none;
      }
    }
  }
  & .activity,
  .schedule {
    text-align: center;
    width: 20%;
    @media print {
      font-size: 11pt;
      width: calc(100% / 3);
    }
  }
  & .description {
    padding: 0 2em;
    width: 50%;
    @include respond-to("md-down") {
      width: 100%;
      margin-top: 20px;
    }
    & .text {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      &::v-deep(p) {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
  & .activity {
    display: flex;
    flex-direction: column;
    @include respond-to("md-down") {
      width: 50%;
    }
    span {
      font-weight: 600;
      margin-top: 8px;
      @media print {
        font-size: 11pt;
      }
    }
  }
  & .location {
    @include respond-to("md-down") {
      width: 50%;
      border-right: none !important;
    }
    text-align: center;
    width: 30%;
    @media print {
      font-size: 11pt;
    }
  }
  & .text {
    margin: 0;
    width: 80%;
    &::v-deep(p) {
      width: 100%;
      & a {
        text-decoration: underline;
      }
      @media print {
        font-size: 11pt;
      }
    }
  }
  & img {
    margin-left: 4px;
    transform: translateY(1px);
    @media print {
      display: none;
    }
  }
}
.tab.first {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
</style>
