<template>
  <div class="content journey">
    <div class="password" v-if="!showContent">
      <div class="passHeader">
        <img :src="`${$URL}assets/img/logo-b.svg`" alt="" />
      </div>
      <div class="passMain">
        <h3 class="s-title">This document requires a password</h3>
        <div class="passForm">
          <input
            type="text"
            placeholder="Password"
            ref="password"
            @keyup.enter="checkPassword()"
            autofocus
          />
          <input type="button" value="Let's Go" @click="checkPassword()" />
        </div>
      </div>
    </div>
    <div class="content" v-if="showContent">
      <div class="header">
        <div class="header-logo">
          <img
            v-if="data.main_logo && data.main_logo.url"
            :src="data.main_logo.url"
            alt=""
          />

          <a href="/" v-if="!(data.main_logo && data.main_logo.url)">
            <img
              :src="`${$URL}assets/img/logo-b.svg`"
              class="logo_hautescotes"
            />
          </a>

          <div
            v-if="data.partner_logo && data.partner_logo.url"
            class="logo-spacer"
          ></div>
          <img
            class="logo-partner"
            :src="data.partner_logo.url"
            alt=""
            v-if="data.partner_logo && data.partner_logo.url"
          />
        </div>
        <div class="contact" v-if="data.contact">
          <span class="text">{{ contact.name }}</span>
          <span class="text"
            >{{ contact["e-mail"] }} <span class="break">,</span>
            {{ contact.phone }}</span
          >
        </div>
      </div>
      <span v-if="data.from" class="dates subtitle">
        <span v-if="data.date_display == 'Full day'">{{
          asDate(data.from).toLocaleDateString(undefined, {
            weekday: "long",
            month: "long",
            day: "numeric",
            timeZone: "Europe/Paris",
          })
        }}</span>
        <span v-if="data.date_display == 'Month only (eg: June 2023)'">{{
          asDate(data.from).toLocaleDateString(undefined, {
            month: "long",
            year: "numeric",
            timeZone: "Europe/Paris",
          })
        }}</span>
        <span
          class="break"
          v-if="data.to && data.from && data.date_display == 'Full day'"
        >
          -
        </span>
        <span v-if="data.to && data.date_display == 'Full day'">{{
          asDate(data.to).toLocaleDateString(undefined, {
            weekday: "long",
            month: "long",
            day: "numeric",
            timeZone: "Europe/Paris",
          })
        }}</span></span
      >
      <h1 v-if="data.journey_title" class="xxl-title">
        {{ asText(data.journey_title) }}
      </h1>
      <span
        v-if="data.journey_subtitle"
        class="dates subtitle journey_subtitle"
      >
        {{ asText(data.journey_subtitle) }}
      </span>
      <component
        :is="
          item.slice_type == 'map'
            ? 'map_bloc'
            : item.slice_type == 'logo___right_description'
            ? false
            : item.slice_type
        "
        v-for="(item, index) in data.body"
        :key="index"
        :data="item"
        :type="data.experience_type"
      ></component>
      <component
        :is="
          item.slice_type != 'logo___right_description'
            ? false
            : item.slice_type
        "
        v-for="(item, index) in data.body"
        :key="index"
        :data="item"
        :type="data.experience_type"
      ></component>
      <div
        class="print"
        v-bind:class="{ 'with-download': data.download_document }"
        @click="printPage()"
      >
        <h3 class="xl-title">Print this page</h3>
      </div>
      <div
        class="print download_document"
        v-if="data.download_document && data.download_document.url"
      >
        <a :href="data.download_document.url" target="_blank" class="xl-title">
          Download additional docs
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { usePrismic } from "@prismicio/vue";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

// const sliceTypes = ["alternate_grid", "program", "centered_text", "map", "references", "logo___right_description", "double_image", "full_page_image"];
import alternate_grid from "@/components/journey/AlternateGrid";
import program from "@/components/journey/Program";
import centered_text from "@/components/journey/CenteredText";
import map_bloc from "@/components/journey/MapBloc";
import references from "@/components/journey/References";
import logo___right_description from "@/components/journey/LogoRightDescription";
import double_image from "@/components/journey/DoubleImage";
import full_page_image from "@/components/journey/FullPageImage";

export default {
  name: "Journey",
  inject: ["$URL"],
  props: {
    uid: {
      type: String,
      default: "",
    },
  },
  components: {
    alternate_grid,
    program,
    centered_text,
    map_bloc,
    references,
    logo___right_description,
    double_image,
    full_page_image,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { client, asDate, asText } = usePrismic();
    const contact = ref({});
    const password = ref(null);
    const showContent = ref(false);

    let data = ref({});

    client
      .getByUID("journey_package", route.params.uid)
      .then((res) => {
        data.value = res.data;
        client.getByID(data.value.contact.id).then((res1) => {
          contact.value = res1.data;
        });
        if (
          typeof data.value.disable_password !== "undefined" &&
          data.value.disable_password === true
        ) {
          showContent.value = true;
        }
      })
      .catch((err) => {
        if (err == "Error: No documents were returned")
          router.push(router.resolve("404", "/"));
      });

    const checkPassword = () => {
      if (password.value.value == data.value.password) {
        showContent.value = true;
      } else {
        password.value.classList.add("error");
        password.value.value = "";
        password.value.setAttribute("placeholder", "Try again");
      }
    };

    const printPage = () => {
      window.print();
    };

    return {
      data,
      contact,
      asDate,
      asText,
      password,
      checkPassword,
      showContent,
      printPage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";

.password {
  & .passHeader {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    & img {
      height: 40px;
    }
  }
  & .passMain {
    height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & h3 {
      margin-bottom: 2rem;
      @include respond-to("md-down") {
        text-align: center;
        max-width: 90%;
      }
    }
    & .passForm {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #6a605b;
      @include respond-to("md-down") {
        flex-direction: column;
      }
      & input[type="text"] {
        width: 15%;
        height: 4rem;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0 2em;
        @include respond-to("md-down") {
          width: 80%;
        }
        &.error {
          border-color: red;
        }
      }
      & input[type="button"] {
        background: rgba(0, 0, 0, 0.1);
        font-size: 14px;
        height: 4rem;
        width: 6%;
        cursor: pointer;
        @include respond-to("md-down") {
          width: 50%;
          margin-top: 4%;
        }
      }
    }
  }
}
.header {
  margin: 0 8rem;
  padding: 5rem 0 6rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  box-sizing: content-box;
  @include respond-to("md-down") {
    margin: 0 2rem;
    padding: 3rem 0;
    flex-direction: column;
    align-items: flex-start;
  }
  @media print {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 2rem;
    padding: 0 0 2rem 0;
    box-sizing: content-box;
  }
  & .header-logo {
    @include respond-to("md-down") {
      width: 100%;
      text-align: center;
    }
    margin-bottom: 2rem;
  }
  & img {
    max-height: 120px;
    vertical-align: middle;
    &.logo_hautescotes {
      height: 40px;
    }
    @include respond-to("md-down") {
      width: auto;
      display: block;
      margin: 0 auto 0 auto;
      &:first-child {
        margin-bottom: 1rem;
      }
    }
    @media print {
      display: inline-block;
      width: auto !important;
      margin: 0;
      padding: 0;
      &:not(:first-child) {
        margin-bottom: 0;
        margin-left: 1cm;
      }
    }
  }
  & .logo-spacer {
    display: inline-block;
    font-size: 2rem;
    font-weight: bold;
    height: 40px;
    position: relative;
    vertical-align: middle;
    width: 30px;
    margin: 0 20px 0 40px;

    &:before {
      content: "X";
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    @include respond-to("md-down") {
      margin: 0 0 1rem 0;
    }
  }
  & .logo-partner {
    height: auto;
    max-height: 120px;
    width: auto;
    @include respond-to("md-down") {
      width: auto !important;
      max-width: 100%;
    }
  }

  & .contact {
    display: flex;
    flex-direction: column;
    & span {
      margin: 0;
      padding: 0;
      display: block;
      min-width: fit-content;
      &:first-child {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        @media print {
          font-size: 10pt;
        }
      }
      &:last-child {
        line-height: auto;
        opacity: 0.3;
        white-space: nowrap;
        @media print {
          font-size: 10pt;
          line-height: 11pt;
        }
        & .break {
          display: inline-block;
          @include respond-to("md-down") {
            display: block;
            height: 0;
          }
        }
      }
    }
  }
}
.dates {
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 8rem;
  color: rgba(0, 0, 0, 0.4);
  text-transform: capitalize;
  @include respond-to("md-down") {
    margin-top: 3rem;
  }
  @media print {
    margin-top: 4rem;
  }
  & .break {
    display: inline-block;
    margin: 0 7px;
    @include respond-to("md-down") {
      display: block;
      height: 0;
    }
    @media print {
      height: auto;
      display: inline-block;
    }
  }
}

h1 {
  font-size: 7rem;
  line-height: 6rem;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  @include respond-to("md-down") {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  @media print {
    font-size: 32pt;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 834px) {
  h1 {
    font-size: 8vw;
    line-height: 8vw;
  }
}
.journey_subtitle {
  margin-top: 4rem;
  text-transform: none;
}

.print {
  text-align: center;
  padding: 8rem 0;
  cursor: pointer;
  @include respond-to("md-down") {
    padding: 3rem 0;
  }
  @media print {
    display: none;
  }
}
.with-download {
  padding: 8rem 0 4rem 0;
}
.download_document {
  padding: 4rem 0 8rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  & a {
    font-family: "IvyJournal";
    font-weight: 200;
    line-height: 1.01;
    text-transform: uppercase;
  }
}
</style>
