<template>
  <div class="container">
    <prismic-rich-text :field="data.primary.text" class="text" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.container {
  padding: 0 8rem;
  margin: 8rem 0;
  display: flex;
  justify-content: center;
  @include respond-to("md-down") {
    padding: 0 2rem;
    margin: 3rem 0;
  }
  & div {
    text-align: center;
  }
  @media print {
    page-break-before: avoid;
    page-break-after: avoid;
  }
}
.text {
  max-width: 37.5%;
  @include respond-to("md-down") {
    max-width: 100%;
  }

  &::v-deep(p) {
    & a {
      text-decoration: underline;
    }
  }
}
</style>
