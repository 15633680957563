<template>
  <div class="container">
    <h2 class="xl-title">{{ asText(data.primary.title) }}</h2>
    <div class="references">
      <div class="reference" v-for="(item, index) in data.items" :key="index">
        <a v-if="item.link.url" :href="item.link.url" target="_blank">
          <prismic-image :field="item.image" />
          <h3 class="xs-title">{{ item.type }}</h3>
          <h4 class="title">{{ asText(item.title) }}</h4>
          <prismic-rich-text :field="item.description" class="text" />
        </a>
        <div v-else>
          <prismic-image :field="item.image" />
          <h3 class="xs-title">{{ item.type }}</h3>
          <h4 class="title">{{ asText(item.title) }}</h4>
          <prismic-rich-text :field="item.description" class="text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { usePrismic } from "@prismicio/vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup() {
    const { asText } = usePrismic();
    return { asText };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.container {
  padding: 0 8rem;
  margin: 8rem 0;
  @include respond-to("md-down") {
    padding: 0 2rem;
    margin: 2rem 0;
  }
  @media print {
    page-break-after: always;
  }
}
.xl-title {
  max-width: 50%;
  margin-top: 10rem;
  margin-bottom: 5rem;
  @include respond-to("md-down") {
    max-width: 100%;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
}
.references {
  display: flex;
  justify-content: flex-start;
  @include respond-to("md-down") {
    flex-direction: column;
  }
  @media print {
    flex-direction: row;
  }
  & .reference {
    max-width: 30%;
    @include respond-to("md-down") {
      max-width: 100%;
      margin-bottom: 2rem;
    }
    @media print {
      margin-right: 1rem;
      max-width: 30%;
      margin-bottom: 1rem;
    }
    img {
      width: 100%;
      @include respond-to("md-down") {
        min-width: 100%;
      }
    }
    .xs-title {
      margin: 0.5rem 0;
    }
    .title {
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
    }
    .text {
      margin-top: 0.5rem;
      opacity: 0.4;
      max-width: 80%;
      @include respond-to("md-down") {
        max-width: 100%;
      }
    }
  }
  & .reference:nth-child(3n - 1) {
    margin: 0 4%;
    @include respond-to("md-down") {
      margin: 0;
      margin-bottom: 2rem;
    }
  }
}
.text {
  &::v-deep(p) {
    & a {
      text-decoration: underline;
    }
  }
}
</style>
