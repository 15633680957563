<template>
  <div class="container">
    <div class="content">
      <div class="img">
        <prismic-image :field="data.primary.image" />
      </div>
      <prismic-rich-text class="text" :field="data.primary.text" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";

.container {
  margin: 4rem 0 0;
  padding: 0 8rem;
  @include respond-to("md-down") {
    margin: 3rem 0 0;
    padding: 0 2rem;
  }
  & .content {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 8rem;
    @media print {
      flex-direction: column;
      align-items: center;
      padding-bottom: 0;
    }
    @include respond-to("md-down") {
      @media not print {
        flex-direction: column;
        align-items: center;
        padding-bottom: 0;
      }
    }
  }
}
.img {
  width: 45%;
  @include respond-to("md-down") {
    @media not print {
      min-width: 100%;
    }
  }
  @media print {
    width: 100%;
    height: 10cm;
    display: flex;
    align-items: center;
    margin-bottom: 1cm;
    overflow: hidden;
  }
  & img {
    width: 100%;
    @media print {
      min-width: 100%;
    }
  }
}
.text {
  display: flex;
  flex-direction: column;
  max-width: 45%;
  padding-left: 10em;
  justify-content: center;
  &::v-deep(p) {
    width: 80%;
    margin-bottom: 30px;
    & a {
      text-decoration: underline;
    }
    @media print {
      font-size: 12pt;
      width: 100%;
      min-width: 100%;
      margin-bottom: 1em;
    }
    @include respond-to("md-down") {
      width: 100%;
    }
  }
  @include respond-to("md-down") {
    max-width: 100%;
    padding-left: 0;
  }
  @media print {
    max-width: 100%;
    padding-left: 0;
    font-size: 12pt;
  }
}
.text {
  &::v-deep(p) {
    & a {
      text-decoration: underline;
    }
  }
}
</style>
