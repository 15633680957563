<template>
  <div class="container">
    <prismic-image :field="data.primary.left_image" />
    <prismic-image :field="data.primary.right_image" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.container {
  padding: 0 8rem;
  margin: 8rem 0;
  display: flex;
  justify-content: space-between;
  @include respond-to("md-down") {
    padding: 0 2rem;
    margin: 2rem 0;
    flex-direction: column;
  }
  @media print {
    display: none;
    flex-direction: row;
    page-break-after: always;
    page-break-before: always;
  }
}
img {
  width: 46%;
  @include respond-to("md-down") {
    min-width: 100%;
    &:last-child {
      margin-top: 2rem;
    }
  }
  @media print {
    min-width: 0px;
    max-width: 48%;
    &:last-child {
      margin-top: 0;
    }
  }
}
.text {
  &::v-deep(p) {
    & a {
      text-decoration: underline;
    }
  }
}
</style>
