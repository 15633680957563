<template>
  <div
    class="container"
    :class="[{ wine: type == 'Wine' }, { art: type == 'Art' }]"
  >
    <!-- <prismic-image :field="data.primary.image"/> -->
    <img
      :src="`${$URL}assets/img/s-logo-w.svg`"
      alt=""
      id="logo"
      :style="'background-image: url(' + data.primary.image.url + ');'"
    />
    <div class="right">
      <prismic-rich-text :field="data.primary.text" class="s-title" />
      <div class="contact">
        <span class="text">{{ contact.name }}</span>
        <span class="text">{{ contact["e-mail"] }}, {{ contact.phone }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { usePrismic } from "@prismicio/vue";
import { ref } from "vue";

export default {
  inject: ["$URL"],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { client } = usePrismic();
    const contact = ref({});

    client.getByID(props.data.primary.contact1.id).then((res) => {
      contact.value = res.data;
    });

    return { contact };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.container {
  &.art {
    background-color: #b79c87;
    @media print {
      background-color: #fff;
      color: #000;
    }
  }
  &.wine {
    background-color: #1d281d;
    @media print {
      background-color: #fff;
      color: #000;
    }
  }
  // padding: 0 8rem;
  margin: 0;
  display: flex;
  color: #ffffff;
  @include respond-to("md-down") {
    flex-direction: column;
  }
  @media print {
    flex-direction: row;
    border-bottom: 0.5mm solid lightgray;
    border-top: 0.5mm solid lightgray;
  }
}
img {
  padding: 6%;
  width: 50%;
  background-position: center center;
  background-size: cover;
  @include respond-to("md-down") {
    min-width: 100%;
  }
  @media print {
    width: 50%;
    min-width: inherit;
  }
}

.right {
  width: 50%;
  padding: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include respond-to("md-down") {
    width: 100%;
    padding: 2rem;
  }
  @media print {
    width: 50%;
  }
  .s-title {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    line-height: 2.75rem;
    text-transform: none;
    text-align: center;
    @media print {
      line-height: 22pt;
      font-size: 16pt;
    }
  }
  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond-to("md-down") {
      margin-top: 4rem;
    }
    & span:first-child {
      font-size: 1.25rem;
      margin: 0;
      margin-bottom: 1rem;
      display: block;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      @media print {
        margin-bottom: 0cm;
        font-size: 12pt;
      }
    }
    & span:last-child {
      font-size: 1.25rem;
      margin: 0;
      display: block;
      opacity: 0.4;
      text-align: center;
      @media print {
        font-size: 12pt;
      }
    }
  }
}
.s-title,
.text {
  &::v-deep(p) {
    & a {
      text-decoration: underline;
    }
  }
}
</style>
