<template>
  <div
    class="container"
    :class="[{ wine: type == 'Wine' }, { art: type == 'Art' }]"
  >
    <div class="left">
      <h2 class="s-title">{{ asText(data.primary.title) }}</h2>
      <prismic-rich-text :field="data.primary.description" class="text" />
      <div>
        <ol ref="ol">
          <li
            v-for="(item, index) in data.items"
            :key="index"
            class="text"
            @click="handleClick(index)"
          >
            <h4>
              <span
                :class="[{ wine: type == 'Wine' }, { art: type == 'Art' }]"
                >{{ index + 1 }}</span
              >
              {{ asText(item.highlight) }}
            </h4>
            <span class="ol_d"
              ><prismic-rich-text
                :field="item.description"
                class="description hidden"
            /></span>
          </li>
        </ol>
      </div>
    </div>
    <div class="right">
      <prismic-image :field="data.primary.map" />
      <h3 class="description text">{{ asText(data.primary.map_name) }}</h3>
    </div>
  </div>
</template>

<script>
import { usePrismic } from "@prismicio/vue";
import { ref } from "vue";
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { asText } = usePrismic();
    const ol = ref(null);

    const handleClick = (index) => {
      ol.value
        .querySelectorAll(".ol_d .description")
        [index].classList.toggle("hidden");
    };

    return { asText, handleClick, ol };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.container {
  padding: 12rem;
  display: flex;
  color: #ffffff;
  @include respond-to("md-down") {
    padding: 4rem 2rem;
    flex-direction: column;
  }
  @media print {
    padding: 0 1rem;
    page-break-before: always;
    color: #000;
    display: block;
  }
  & .left,
  .right {
    width: 50%;
    @include respond-to("md-down") {
      width: 100%;
    }
    @media print {
      width: 100%;
    }
  }
  & .left {
    padding-right: 4%;
    & .text {
      @media print {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
      }
    }
    @include respond-to("md-down") {
      padding-right: 0;
    }
    @media print {
      padding-right: 0;
    }
  }
  & .right {
    padding-left: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond-to("md-down") {
      padding-left: 0;
    }
    @media print {
      padding-left: 0;
      page-break-before: always;
      page-break-after: always;
    }
    & h3 {
      @include respond-to("md-down") {
        margin-bottom: 0;
      }
      @media print {
        margin-bottom: 0;
      }
    }
    & img {
      @include respond-to("md-down") {
        min-width: 100%;
      }
    }
  }
  & h2 {
    margin-bottom: 2.5rem;
    @media print {
      margin-bottom: 0;
    }
  }
  & ol {
    margin-top: 6rem;
    @media print {
      margin-top: 0;
    }
    & li {
      margin-bottom: 1.5rem;
      cursor: pointer;
      @media print {
        margin-bottom: 0.5cm;
      }
      & h4 {
        & span {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #ffffff;
          border-radius: 100%;
          height: 25px;
          width: 25px;
          font-size: 14px;
          margin-right: 1rem;
          @media print {
            border: 1px solid #000000;
            height: 0.65cm;
            width: 0.65cm;
            margin-right: 0.2cm;
          }
          &.art {
            color: #b79c87;
            @media print {
              color: #000000;
            }
          }
          &.wine {
            color: #1d281d;
            @media print {
              color: #000000;
            }
          }
        }
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 0.75rem;
      }
      & .description {
        height: auto;
        overflow: hidden;
        transition: all 0.8s ease-out;
        @media print {
          width: 80%;
          max-width: 80%;
          min-width: 80%;
        }
        &.hidden {
          height: 0px;
          @media print {
            height: auto;
          }
        }
      }
    }
  }
  &.art {
    background-color: #b79c87;
    @media print {
      background-color: #ffffff;
    }
  }
  &.wine {
    background-color: #1d281d;
    @media print {
      background-color: #ffffff;
    }
  }
}
.text {
  &::v-deep(p) {
    & a {
      text-decoration: underline;
    }
  }
}
img {
  width: 100%;
}
</style>
